<template>
  <a-spin :spinning="loading || spinning">
    <div class="record-list">

      <div class="record-list__title">
        <span class="fw-500 font-16">{{reply.name}}</span>
      </div>

      <div v-if="isDisplayAgree"
           class="record-list__agree">
        {{isAgree ? '对方已同意存档会话内容' : '对方不同意存档会话内容，您将无法继续提供服务'}}
      </div>

      <div v-if="isLoadingMoreMsg"
           class="text-center">
        <Loading3QuartersOutlined class="mv12 color-primary"
                                  spin
                                  :style="{fontSize: '20px', color: '#31A1FF'}" />
      </div>

      <div v-if="msg.list.length"
           ref="msgListRef"
           class="record-list__content"
           @scroll="loadMoreRecord">

        <ul class="msg-list">
          <li v-for="msg  in msg.list"
              :key="msg.workWechatMsgId"
              class="msg-li"
              :class="direction(msg)">
            <template v-if="msg.msgType === 'time'">
              {{$f.datetime(msg.sendMsgTime, 'YYYY-MM-DD HH:mm')}}
            </template>

            <div v-else
                 class="msg-li__container"
                 :data-msg-id="msg.workWechatMsgId">
              <img v-if="msg.senderAvatar"
                   class="user-avatar"
                   :src="msg.senderAvatar"
                   alt="用户头像">
              <svg-icon v-else
                        type="iconmorentouxiang"
                        class="font-32"></svg-icon>

              <div class="content-wrap">
                <span v-if="isDisplaySenderName(msg)"
                      class="font-14 ml8">{{msg.senderName}}</span>
                <record-item :msg="msg.content"></record-item>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <a-empty v-else
               description="暂无存档数据" />
    </div>
  </a-spin>

</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive, watch, nextTick } from "vue";
import { Empty } from "ant-design-vue";

import RecordItem from "@/views/corpRiskControl/chatRecord/RecordItem";
import SvgIcon from "@/components/SvgIcon";

import chatRecordApi from "@/service/api/chatRecord";

export default defineComponent({
  name: "RecordList",

  props: {
    reply: {
      type: Object,
      default: () => ({}),
    },
    type: String,
    senderId: [String, Number],
    loading: Boolean,
  },

  components: {
    AEmpty: Empty,
    RecordItem,
    SvgIcon,
  },

  setup(props, { attrs }) {
    const spinning = ref(false);

    const searchForm = reactive({
      chatSearchKey: props.reply?.searchKey,
      chatMemberType: props.type,
      page: 0,
      size: 50,
    });

    const msgListRef = ref();

    const msg = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    // * 上滑加载，并重新定位滚动条位置
    const isLoadingMoreMsg = ref(false);
    const loadMoreRecord = _.debounce(async () => {
      if (!msgListRef.value) {
        return;
      }

      if (msgListRef.value.scrollTop > 30 || msg.finished) {
        return;
      }

      isLoadingMoreMsg.value = true;
      const oldScrollHeight = msgListRef.value.scrollHeight;

      await getMsgList();
      await nextTick();
      const newScrollHeight = msgListRef.value.scrollHeight;

      msgListRef.value.scrollTop = newScrollHeight - oldScrollHeight;
      isLoadingMoreMsg.value = false;
    }, 500);

    async function getMsgList() {
      msg.loading = true;
      const res = await chatRecordApi.getChatMsgList(searchForm);

      searchForm.page++;

      const newArr = insertMsgTime(res.data);
      msg.list = _.concat(_.reverse(newArr), msg.list);

      msg.loading = false;

      if (msg.list.length >= res.total) {
        msg.list.unshift({
          msgType: "time",
          workWechatMsgId: msg.list[0].workWechatMsgId,
          sendMsgTime: msg.list[0].sendMsgTime,
        });

        msg.finished = true;
      }
    }

    function insertMsgTime(arr) {
      let newArr = [];
      _.forEach(arr, (recordItem, index) => {
        const timeMark = {
          msgType: "time",
          workWechatMsgId: recordItem.workWechatMsgId,
          sendMsgTime: recordItem.sendMsgTime,
        };

        if (
          index === 0 &&
          msg.list.length !== 0 &&
          msg.list[0].sendMsgTime - recordItem.sendMsgTime > 300000
        ) {
          newArr.push({
            msgType: "time",
            workWechatMsgId: msg.list[0].workWechatMsgId,
            sendMsgTime: msg.list[0].sendMsgTime,
          });
          newArr.push(recordItem);

          return;
        }

        if (
          index < arr.length - 1 &&
          recordItem.sendMsgTime - arr[index + 1].sendMsgTime > 300000
        ) {
          newArr.push(recordItem);
          newArr.push(timeMark);
          return;
        }

        newArr.push(recordItem);
      });
      return newArr;
    }

    const direction = (msg) => {
      if (msg.msgType === "time") {
        return "msg-time";
      }

      return props.senderId === msg.senderId ? "send" : "receive";
    };

    // * 获取当前客户是否同意会话存档
    const isAgree = ref(true);
    async function getChatAgree() {
      await chatRecordApi
        .getChatAgree({
          staffId: attrs.staffId,
          customerId: attrs.customerId,
        })
        .then((res) => {
          isAgree.value = res.ok;
        });
    }

    watch(
      () => props.reply,
      async () => {
        spinning.value = true;
        await getChatAgree();

        searchForm.page = 0;
        msg.list = [];
        msg.finished = false;

        if (!_.keys(props.reply).length) {
          spinning.value = false;
          return;
        }

        reloadMsgChat();
      }
    );

    async function reloadMsgChat() {
      searchForm.chatSearchKey = props.reply.searchKey;
      searchForm.chatMemberType = props.type;

      await getMsgList();
      await nextTick();

      if (msg.list.length && msgListRef.value) {
        msgListRef.value.scrollTop = msgListRef.value.scrollHeight;
      }

      spinning.value = false;
    }

    return {
      spinning,
      searchForm,

      msgListRef,
      msg,
      isLoadingMoreMsg,
      loadMoreRecord,

      direction,

      isAgree,
    };
  },

  computed: {
    isDisplayAgree() {
      return this.type === "staff_customer" && this.reply.id;
    },
    isDisplaySenderName() {
      return function (msg) {
        return this.type === "group" && this.direction(msg) === "receive";
      };
    },
  },
});
</script>
<style lang="less" scoped>
.ant-empty {
  margin-top: 240px;
}
:deep(.ant-empty-description) {
  color: @gray;
}
</style>
