import {apiClient as client} from '@/shared/service/api-client';

export default {
  async getStaffList(params) {
    return await client.get('/conversationArchive/staffList', {params: params});
  },
  async getStaffsCustomerList(params) {
    return await client.get('/conversationArchive/staffsCustomerList', {params: params});
  },
  async getStaffsWorkerList(params) {
    return await client.get('/conversationArchive/staffsWorkerList', {params: params});
  },
  async getStaffsGroupList(params) {
    return await client.get('/conversationArchive/staffsGroupList', {params: params});
  },
  

  async getCustomerList(params) {
    return await client.get('/conversationArchive/customerList', {params: params});
  },

  async getCustomersStaffList(params) {
    return await client.get('/conversationArchive/customersStaffList', {params: params});
  },
  async getCustomersGroupList(params) {
    return await client.get('/conversationArchive/customersGroupList', {params: params});
  },

  async getGroupList(params) {
    return await client.get('/conversationArchive/groupList', {params: params});
  },

  async getChatMsgList(params) {
    return await client.get('/conversationArchive/getChatMsgList', {params: params});
  },

  async getChatAgree(params) {
    return await client.get('/conversationArchive/staffCustomerChatAgree', {params: params});
  },
}